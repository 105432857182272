<template>
    <section class="sms-reminder">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="width: 200px" size="small"/>
            <div style="width: 9px;"></div>
            <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" size="small"
                            start-placeholder="开始日期" end-placeholder="结束日期"/>
            <div style="width: 9px;"></div>
            <el-select v-model="formSearch.status" placeholder="请选择" size="small" style="width: 200px">
                <el-option label="发送失败" :value="0"/>
                <el-option label="发送成功" :value="1"/>
                <el-option label="租户接收成功" :value="2"/>
                <el-option label="租户已读" :value="3"/>
            </el-select>
            <div style="width: 9px;"></div>
            <el-input v-model="formSearch.type" placeholder="请输入类型" style="width: 200px" size="small"/>
            <div style="width: 9px;"></div>
            <el-input v-model="formSearch.apartmentType" placeholder="请输入短信房源类型" style="width: 200px" size="small"/>
            <div style="width: 9px;"></div>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getAlertTableData" :columns="tableColumn"
                   :query="formSearch" :height="820">
            <!--<el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="handlePublishDelete(row)">删除</span>
                    </div>
                </template>
            </el-table-column>-->
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {tableColumn} from "./data";
    import {getAlertApi} from "@/api/alert";
    export default {
        name: "sms-reminder",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: null,
                    status: null,
                    type: null,
                    apartmentType: null,
                }
            };
        },
        components: {},
        mounted() {},
        methods: {
            getAlertTableData(params) {
                return getAlertApi(params);
            },
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            handleReset(){
                this.formSearch.keyword = null;
                this.handleSearch();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .sms-reminder {
        padding: 0 VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(15px);
            padding: VH(15px) VW(15px);

            .addPublish {
                position: absolute;
                left: VW(40px);
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
import { timeFormat, numberFormat } from "@custom/index";
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60,
    },
    {
        prop: "apartmentType",
        label: "模块",
        width: 100
    },
    {
        prop: "type",
        label: "类型",
        width: 100
    },
    {
        prop: "leasor.name",
        label: "租户姓名",
        width: 100
    },
    {
        prop: "phone",
        label: "租户手机",
        width: 100
    },
    {
        prop: "content",
        label: "短信提醒内容",
        showOverflowTooltip: true
    },
    {
        prop: "sendTime",
        label: "发送时间",
        width: 150,
        render: (h, { row }) => {
            const {sendTime} = row;
            return h("span", sendTime?timeFormat(new Date(sendTime),"yyyy-MM-dd HH:mm:ss"):"");
        }
    },
    {
        prop: "sendType",
        label: "消息类型",
        width: 100
    },
    {
        prop: "status",
        label: "消息状态",
        width: 100,
        render: (h, { row }) => {
            const {status} = row;
            if(status===0) return h("span", "发送失败");
            if(status===1) return h("span", "发送成功");
            if(status===2) return h("span", "租户接收成功");
            if(status===3) return h("span", "租户已读");
        }
    }
];

export const apartmentColumn = [
    {
        type: "selection",
        width: 60
    },
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "name",
        label: "地址"
    },
    {
        prop: "apartmentType",
        label: "户型",
        width: 100
    },
    {
        prop: "publishArea",
        label: "面积",
        width: 100,
        render: (h, { row }) => {
            return h("span", row.publishArea?row.publishArea+"㎡":"0");
        }
    },
];

// 新增、编辑房型的表单验证
export function formPublishRules() {
    return {
        templateName: [
            { required: true, message: '请输入房型名称', trigger: 'blur' },
        ],
        roomSize: [
            { required: true, message: '请输入室数量', trigger: ['blur', 'change'] },
        ],
        officeSize: [
            { required: true, message: '请输入厅数量', trigger: ['blur', 'change'] },
        ],
        kitchenSize: [
            { required: true, message: '请输入厨房数量', trigger: ['blur', 'change'] },
        ],
        toiletSize: [
            { required: true, message: '请输入卫生间数量', trigger: ['blur', 'change'] },
        ],
        /*area: [
            { required: true, message: '请输入房间面积', trigger: 'blur' },
        ],*/
        houseOrientation: [
            { required: true, message: '请选择房屋朝向', trigger: 'blur' },
        ],
        /*minAmount: [
            { required: true, message: '请输入最小租金', trigger: 'blur' },
        ],
        maxAmount: [
            { required: true, message: '请输入最大租金', trigger: 'blur' },
        ],*/
        decorationStyle: [
            { required: true, message: '请选择装修风格', trigger: 'blur' },
        ],
        houseManageName: [
            { required: true, message: '请选择房管员姓名', trigger: 'blur' },
        ],
        houseManagePhone: [
            { required: true, message: '请输入房管员手机号码', trigger: 'blur' },
        ],
        /*paymentMethod: [
            { required: true, message: '请选择房付款方式', trigger: 'blur' },
        ],*/
        labels: [
            { required: true, message: '请选择房屋标签', trigger: 'blur' },
        ],
        configs: [
            { required: true, message: '请选择房间配置', trigger: 'blur' },
        ],
        houseDescription: [
            { required: true, message: '请输入房源描述', trigger: 'blur' },
            { min: 50, max: 500, message: '房源描述长度在 50 到 500 个字符', trigger: 'blur' }
        ],
        samplePhotos: [
            { required: true, message: '请添加房间模板照片', trigger: 'blur' },
        ],
		amenities: [],
		safety: []
    };
}
